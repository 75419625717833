
import { defineComponent } from "vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-clients-policy-table-dropdown",
  components: {},
  props: {
    publicId: String,
    code: String
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      removeLastHyphen
    } = ResusableFunctions();

    return {
      useReusableNavigation,
      removeLastHyphen,
      reinitialization
    };
  }
});
