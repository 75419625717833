
import { defineComponent } from "vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "broker-clients-policy-table-dropdown",
  components: {},
  props: {
    publicId: String,
    code: String,
  },
  setup() {
    const { reinitialization, useReusableNavigation } = ResusableFunctions();

    const redirect = (value) => {
      let route = "";

      if (
        ["HOM-HOU-OWN", "HOM-HOU", "HOM-HOU-OWN-", "HOM-HOU-"].includes(value)
      ) {
        route = "broker-client-owned-building-policy-information";
      } else if (value === "HOM-HOU-TEN") {
        route = "broker-client-rented-building-policy-information";
      } else if (value === "HOM-HOU-HPA") {
        route = "personal-accident-house-policy-information";
      } else if (value === "HOM-HOU-CON") {
        route = "contents-house-policy-information";
      } else if (value === "MOT") {
        route = "broker-client-motor-insurance-policy-information";
      } else {
        route = "";
      }

      return route;
    };

    return {
      useReusableNavigation,
      reinitialization,
      redirect,
    };
  },
  methods: {},
});
