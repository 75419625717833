
import { defineComponent } from "vue";
import BrokerPolicyTableDropdownOptions from "@/components/individual/BrokerPolicyTableDropdownOptions.vue";
import AdminPolicyTableDropdownOptions from "@/components/individual/AdminPolicyTableDropdownOptions.vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import variables from "@/router/api";
import $ from "jquery";
import { useStore } from "vuex";

export default defineComponent({
  name: "policies-table",
  components: {
    BrokerPolicyTableDropdownOptions,
    AdminPolicyTableDropdownOptions,
  },
  props: {
    widgetClasses: String,
    policies: Array,
  },
  data() {
    return {
      cooperativeAdminRole: "",
      adminRole: "",
      brokerRole: "",
      superAdminRole: "",
    };
  },
  setup() {
    const store = useStore();
    const userType = store.getters.currentUser;

    return {
      userType,
    };
  },
  created() {
    this.cooperativeAdminRole = variables.COOPERATIVE_ADMIN_USER_ROLE;
    this.brokerRole = variables.BROKER_USER_ROLE;
    this.adminRole = variables.ADMIN_USER_ROLE;
    this.superAdminRole = variables.SUPER_ADMIN_USER_ROLE;
    this.displayDatatable();
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
        });
      }, 3000);
    },
    monthsCalculator(startDate) {
      const month = variables.monthsCalculator(
        startDate,
        variables.currentDate()
      );

      return month == 3
        ? "month-1"
        : month == 2
        ? "month-2"
        : month == 1
        ? "month-3"
        : "";
    },
  },
});
